export const i18nDefault = {
  default: {
    ACCESS_PANEL__ACCESS: "Access",
    ACCESS_PANEL__ADD_EXISTING: "Add existing users",
    ACCESS_PANEL__EMAIL: "Email",
    ACCESS_PANEL__ERROR_2: "User {0} cannot be added as they have not completed registration",
    ACCESS_PANEL__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    ACCESS_PANEL__ERROR: "An error has occurred.",
    ACCESS_PANEL__NO_DATA: "No data available",
    ACCESS_PANEL__REVOKE: "Revoke",
    ACCESS_PANEL__ROLE: "Role",
    ACCESS_PANEL__SUCCESS_REVOKE: "You've revoked access for user {0}",
    ACCESS_PANEL__SUCCESS: "You've added access for {0}",
    ACCOUNT__ACCOUNT: "Account",
    ACCOUNT__CHANGE_PASSWORD: "Change password",
    ACCOUNT__DELETE_ACCOUNT: "Delete account",
    ACCOUNT__EMAIL: "Email",
    ACCOUNT__IMPRINT: "Imprint",
    ACCOUNT__LEGAL: "Legal",
    ACCOUNT__LOGOUT: "Logout",
    ACCOUNT__NAME: "Name",
    ACCOUNT__PRIVACY_POLICY: "Privacy policy",
    ACCOUNT__SWITCH_TO_ADMIN_PORTAL: "Switch to Admin portal",
    ACCOUNT__SWITCH_TO_RESEARCHER_PORTAL: "Switch to Researcher portal",
    ACCOUNT__TERMS_OF_USE: "Terms of use",
    ACCOUNT_BUTTON__ACCOUNT: "Account",
    ACCOUNT_BUTTON__LOGIN: "Login",
    ACCOUNT_TAB__LINK: "Account",
    ACCOUNT_TAB__MENU_ITEMS_CHANGE_PASSWORD: "Change password",
    ACCOUNT_TAB__MENU_ITEMS_LEGAL: "Legal",
    ACCOUNT_TAB__MENU_ITEMS_LOGOUT: "Logout",
    ACTION_SELECTOR__CREATE_CACHE: "Create cache",
    ACTION_SELECTOR__CREATE_DATA_MART: "Create data mart",
    ACTION_SELECTOR__CREATE_RELEASE: "Create release",
    ACTION_SELECTOR__DELETE_DATASET: "Delete dataset",
    ACTION_SELECTOR__PERMISSIONS: "Permissions",
    ACTION_SELECTOR__RESOURCES: "Resources",
    ACTION_SELECTOR__SELECT_ACTION: "Select action",
    ACTION_SELECTOR__UPDATE_DATASET: "Update dataset",
    ACTION_SELECTOR__UPDATE_SCHEMA: "Update schema",
    ACTION_SELECTOR__RUN_DATA_QUALITY: "Run data quality",
    ACTION_SELECTOR__RUN_DATA_CHARACTERIZATION: "Run data characterization",
    ADD_FLOW_DIALOG__ACTION: "Action",
    ADD_FLOW_DIALOG__ADD_FLOW: "Add flow",
    ADD_FLOW_DIALOG__BYTES: "bytes",
    ADD_FLOW_DIALOG__CANCEL: "Cancel",
    ADD_FLOW_DIALOG__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    ADD_FLOW_DIALOG__ERROR: "An error has occurred.",
    ADD_FLOW_DIALOG__FILE: "File",
    ADD_FLOW_DIALOG__FILENAME: "Filename",
    ADD_FLOW_DIALOG__GIT_URL: "Git url",
    ADD_FLOW_DIALOG__NO_FILE_AVAILABLE: "No file available",
    ADD_FLOW_DIALOG__SELECT_FILE: "Select file",
    ADD_FLOW_DIALOG__SIZE: "Size",
    ADD_FLOW_DIALOG__UPLOAD_ERROR: "Uploaded file type not supported",
    ADD_FLOW_DIALOG__UPLOAD_METHOD: "Upload Method",
    ADD_FLOW_DIALOG__VIA_FILE_UPLOAD: "via file upload",
    ADD_FLOW_DIALOG__VIA_URL: "via url",
    ADD_STUDY_DIALOG__ADD_DATASET: "Add dataset",
    ADD_STUDY_DIALOG__ADD_METADATA: "add metadata",
    ADD_STUDY_DIALOG__ADD: "Add",
    ADD_STUDY_DIALOG__CANCEL: "Cancel",
    ADD_STUDY_DIALOG__CDM_SCHEMA_OPTION: "CDM Schema Option",
    ADD_STUDY_DIALOG__CREATE_DATA_CLEANSING: "Create second CDM for data cleansing",
    ADD_STUDY_DIALOG__CREATE_FHIR: "Create FHIR server staging area",
    ADD_STUDY_DIALOG__CREATE_NEW_SCHEMA_EXISTING_NAME: "Create new schema with existing name",
    ADD_STUDY_DIALOG__CREATE_NEW_SCHEMA: "Create new schema",
    ADD_STUDY_DIALOG__DATA_MODEL_OPTION: "Data Model Option",
    ADD_STUDY_DIALOG__CUSTOM_DATA_MODEL_OPTION: "Custom Data Model Option",
    ADD_STUDY_DIALOG__DATA_VISIBILITY: "Dataset Visibility",
    ADD_STUDY_DIALOG__DATABASES: "Databases",
    ADD_STUDY_DIALOG__DATASET_CODE_ALLOWED_VALUES: "Should only contain letters, numbers, and underscores",
    ADD_STUDY_DIALOG__DATASET_NAME: "Dataset name - Displayed on buttons and headers",
    ADD_STUDY_DIALOG__DATASET_SUMMARY: "Dataset summary",
    ADD_STUDY_DIALOG__DESCRIPTION: "Description",
    ADD_STUDY_DIALOG__ENTER_VALID_DATASET_CODE: "Please enter a valid Token dataset code",
    ADD_STUDY_DIALOG__EXISTING_SCHEMA: "Use existing schema",
    ADD_STUDY_DIALOG__HIDDEN: "Hidden (only researchers and the tenant admin)",
    ADD_STUDY_DIALOG__INFO_CONFIG: "Dataset info configuration",
    ADD_STUDY_DIALOG__INVALID_SCHEMA_NAME: "Invalid schema name",
    ADD_STUDY_DIALOG__METADATA: "Metadata",
    ADD_STUDY_DIALOG__NO_AVAILABLE_SCHEMA: "No available schemas found. Please select another schema option.",
    ADD_STUDY_DIALOG__NO_SCHEMA: "No schema",
    ADD_STUDY_DIALOG__PA_CONFIG: "PA Config",
    ADD_STUDY_DIALOG__PRIVATE: "Private (only users from the tenant)",
    ADD_STUDY_DIALOG__PUBLIC: "Public",
    ADD_STUDY_DIALOG__REQUIRED: "This is required",
    ADD_STUDY_DIALOG__SCHEMA_NAME: "Schema name",
    ADD_STUDY_DIALOG__SHOW_REQUEST_ACCESS: "Show request access button",
    ADD_STUDY_DIALOG__TAGS: "Tags",
    ADD_STUDY_DIALOG__TOKEN_DATASET_CODE: "Token dataset code",
    ADD_STUDY_DIALOG__TYPE: "Type",
    ADD_STUDY_DIALOG__USE_SAME_SCHEMA: "Use same CDM schema for vocab schema",
    ADD_STUDY_DIALOG__VISIBILITY_STATUS_GROUP: "visibilityStatusGroup",
    ADD_STUDY_DIALOG__VOCAB_SCHEMA_NAME: "Vocab Schema Name",
    ADD_TAG_DIALOG__ADD_TAG: "Add Tag",
    ADD_TAG_DIALOG__CANCEL: "Cancel",
    ADD_TAG_DIALOG__SAVE: "Save",
    ADD_TAG_DIALOG__SUCCESS: "Tag Config added successfully.",
    ADD_TAG_DIALOG__TAG_NAME: "Tag Name",
    ADD_USER_DIALOG__ADD_USER: "Add user",
    ADD_USER_DIALOG__ADD: "Add",
    ADD_USER_DIALOG__CANCEL: "Cancel",
    ADD_USER_DIALOG__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    ADD_USER_DIALOG__ERROR: "An error has occurred.",
    ADD_USER_DIALOG__GENERATE: "Generate",
    ADD_USER_DIALOG__HIDE_PASSWORD: "Hide password",
    ADD_USER_DIALOG__PASSWORD: "Password",
    ADD_USER_DIALOG__SHOW_PASSWORD: "Show password",
    ADD_USER_DIALOG__USERNAME_HELPER: "Username should only contain letters, numbers, or underscore.",
    ADD_USER_DIALOG__USERNAME: "Username",
    ANALYSIS_DIALOG__TITLE: "Run {0} for dataset: {1}",
    ANALYSIS_DIALOG__FORM_TITLE: "Dataset Analysis Configuration",
    ANALYSIS_DIALOG__DATA_QUALITY: "Data Quality",
    ANALYSIS_DIALOG__DATA_CHARACTERIZATION: "Data Characterization",
    ANALYSIS_DIALOG__CANCEL: "Cancel",
    ANALYSIS_DIALOG__RUN: "Run Analysis",
    ANALYSIS_DIALOG__RUN_SUCCESS: "Successfully generated {0} job for {1}",
    ATTRIBUTE_TABLE__ADD_ATTRIBUTE: "Add Attribute",
    ATTRIBUTE_TABLE__ATTRIBUTE_ID: "Attribute Id",
    ATTRIBUTE_TABLE__ATTRIBUTE_NAME: "Attribute Name",
    ATTRIBUTE_TABLE__CATEGORY: "Category",
    ATTRIBUTE_TABLE__DATATYPE: "Datatype",
    ATTRIBUTE_TABLE__DELETE: "Delete",
    ATTRIBUTE_TABLE__EDIT: "Edit",
    ATTRIBUTE_TABLE__LOADER: "Loading Attribute Configs",
    ATTRIBUTE_TABLE__METADATA: "Metadata",
    AZURE_AD__SAVE: "Save",
    AZURE_AD__TEXT_FIELD_1_LABEL: "Azure Group ID for Tenant Viewer",
    AZURE_AD__TEXT_FIELD_2_LABEL: "Azure Group ID for System Admin",
    AZURE_AD__TEXT_FIELD_3_LABEL: "Azure Group ID for User Admin",
    AZURE_AD__TITLE: "Azure AD integration",
    BAR_CHART__NO_DATA: "No data",
    BOX_PLOT_CHART__NO_DATA: "No data",
    CDM_DOWNLOAD_DIALOG__DOWNLOADING_ZIP: "Downloading Zip. This could take a while if the data is large...",
    CDM_DOWNLOAD_DIALOG__ERROR_DOWNLOAD:
      "Error occurred while downloading patient data. To report the error, please send an email to help@data4life.care",
    CDM_DOWNLOAD_DIALOG__ERROR_ZIP:
      "Error occurred while retrieving ZIP from MRI. To report the error, please send an email to help@data4life.care",
    CDM_DOWNLOAD_DIALOG__PLEASE_WAIT: "Please wait",
    CDM_SUMMARY__NO_DATA: "No data",
    CDM_SUMMARY__NUMBER_OF_PERSONS: "Number of persons",
    CDM_SUMMARY__SOURCE_NAME: "Source name",
    CDM_SUMMARY__TITLE: "CDM Summary",
    CHANGE_MY_PASSWORD_DIALOG__BUTTON_CANCEL: "Cancel",
    CHANGE_MY_PASSWORD_DIALOG__BUTTON_GENERATE: "Generate",
    CHANGE_MY_PASSWORD_DIALOG__BUTTON_UPDATE: "Update",
    CHANGE_MY_PASSWORD_DIALOG__DIALOG_TEXT_FIELD_LABEL_1: "Old password",
    CHANGE_MY_PASSWORD_DIALOG__DIALOG_TEXT_FIELD_LABEL_2: "New password",
    CHANGE_MY_PASSWORD_DIALOG__DIALOG_TITLE: "Change password",
    CHANGE_MY_PASSWORD_DIALOG__DIALOG_TOOLTIP_TITLE_1: "Hide password",
    CHANGE_MY_PASSWORD_DIALOG__DIALOG_TOOLTIP_TITLE_2: "Show password",
    CHANGE_MY_PASSWORD_DIALOG__PASSWORD_UPDATED_ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    CHANGE_MY_PASSWORD_DIALOG__PASSWORD_UPDATED_ERROR_MESSAGE: "An error has occurred.",
    CHANGE_MY_PASSWORD_DIALOG__PASSWORD_UPDATED: "Password updated",
    CHANGE_USER_PASSWORD_DIALOG__CANCEL: "Cancel",
    CHANGE_USER_PASSWORD_DIALOG__CHANGE_USER_PASSWORD: "Change user password",
    CHANGE_USER_PASSWORD_DIALOG__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    CHANGE_USER_PASSWORD_DIALOG__ERROR: "An error has occurred.",
    CHANGE_USER_PASSWORD_DIALOG__GENERATE: "Generate",
    CHANGE_USER_PASSWORD_DIALOG__HIDE_PASSWORD: "Hide password",
    CHANGE_USER_PASSWORD_DIALOG__PASSWORD_UPDATED: "Password updated",
    CHANGE_USER_PASSWORD_DIALOG__PASSWORD: "Password",
    CHANGE_USER_PASSWORD_DIALOG__SHOW_PASSWORD: "Show password",
    CHANGE_USER_PASSWORD_DIALOG__UPDATE: "Update",
    COHORT__MISSING_USER_ID: "Missing User Id and Dataset Id",
    COHORT_DEFINITION_LIST__CREATION_TIMESTAMP: "Created",
    COHORT_DEFINITION_LIST__DELETE: "Delete",
    COHORT_DEFINITION_LIST__DESCRIPTION: "Description",
    COHORT_DEFINITION_LIST__ERROR_OCCURRED: "An error has occurred.",
    COHORT_DEFINITION_LIST__ID: "ID",
    COHORT_DEFINITION_LIST__JOBS: "Jobs",
    COHORT_DEFINITION_LIST__MODIFICATION_TIMESTAMP: "Modified",
    COHORT_DEFINITION_LIST__NAME: "Name",
    COHORT_DEFINITION_LIST__OWNER: "Owner",
    COHORT_DEFINITION_LIST__PATIENT_COUNT: "Patient Count",
    COHORT_DEFINITION_LIST__TRY_AGAIN:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    COHORT_DELETE_DIALOG__ARE_YOU_SURE: "Are you sure you want to delete the following cohort",
    COHORT_DELETE_DIALOG__CANCEL: "Cancel",
    COHORT_DELETE_DIALOG__CONFIRM: "Yes, delete",
    COHORT_DELETE_DIALOG__DELETE_COHORT: "Delete Cohort",
    COHORT_DELETE_DIALOG__DELETE_SUCCESSFUL: "Cohort deleted successfully.",
    COHORT_DELETE_DIALOG__DESCRIPTION: "Description",
    COHORT_DELETE_DIALOG__ERROR_OCCURRED_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    COHORT_DELETE_DIALOG__ERROR_OCCURRED: "An error has occurred.",
    COHORT_DELETE_DIALOG__NAME: "Name",
    COHORT_HIERARCHY__ERROR: "Please try again.",
    COHORT_HIERARCHY__ERROR_DESCRIPTION: "An error has errored.",
    COHORT_FILTER__COHORT: "Cohort",
    COHORT_FILTER__NO_COHORTS: "No cohorts found in study",
    COHORT_FILTER__SELECT_COHORT_FILTER: "Select cohort filter",
    COHORT_PLUGIN__COHORT_MANAGEMENT: "Cohort Management",
    COHORT_PLUGIN__COHORTS: "Cohorts",
    COHORT_SURVIVAL__ERROR_OCCURRED: "An error has occurred.",
    COHORT_SURVIVAL__TITLE: "Kaplan-Meier Survival Analysis",
    COHORT_SURVIVAL__SELECT_TARGET_COHORT: "Select target Cohort",
    COHORT_SURVIVAL__SELECT_OUTCOME_COHORT: "Select Outcome Cohort",
    COHORT_SURVIVAL__GRAPH_LOADING: "Graph is loading...",
    COHORT_SURVIVAL__RUN_SURVIVAL_ANALYSIS: "Run Survival Analysis",
    COHORT_SURVIVAL__TRY_AGAIN: "Please try again. To report the error, please send an email to help@data4life.care.",
    CONCEPT_SETS__SEARCH: "Concept Search",
    CONCEPT_SETS__ADD_CONCEPT_SET: "Add concept set",
    CONCEPT_SETS__AUTHOR: "Author",
    CONCEPT_SETS__CREATED: "Created",
    CONCEPT_SETS__ERROR_DESCRIPTION: "Please try again.",
    CONCEPT_SETS__ERROR: "An error has occurred",
    CONCEPT_SETS__ID: "ID",
    CONCEPT_SETS__LIST: "Concept Set List",
    CONCEPT_SETS__Name: "Name",
    CONCEPT_SETS__REFERENCE_CONCEPTS: "Reference concepts from dataset",
    CONCEPT_SETS__SHARED: "Shared",
    CONCEPT_SETS__UPDATED: "Updated",
    COPY_STUDY_DIALOG__CANCEL: "Cancel",
    COPY_STUDY_DIALOG__COHORT_FILTER: "Cohort Filter",
    COPY_STUDY_DIALOG__CREATE_DATA_MART: "Create data mart - {0}",
    COPY_STUDY_DIALOG__CREATE: "Create",
    COPY_STUDY_DIALOG__DATE_FILTER: "Date Filter",
    COPY_STUDY_DIALOG__DEFINITION_FETCH_ERROR:
      "Error loading Cohort Definitions, Please try refreshing page to load again",
    COPY_STUDY_DIALOG__METADATA_FETCH_ERROR: "Error Study Schema Metadata, Please try refreshing page to load again",
    COPY_STUDY_DIALOG__NEW_DATASET_NAME: "New dataset name",
    COPY_STUDY_DIALOG__NO_TABLE_CHOSEN: "Table Filter is checked, but no table was chosen!",
    COPY_STUDY_DIALOG__SNAPSHOT_LOCATION_1: "Snapshot Location",
    COPY_STUDY_DIALOG__SNAPSHOT_LOCATION_2: "snapshotLocation",
    COPY_STUDY_DIALOG__SNAPSHOT_LOCATION_REQUIRED: "Snapshot location required!",
    COPY_STUDY_DIALOG__STORE_SNAPSHOTS_AS_PARQUET: "Store snapshots as parquet file",
    COPY_STUDY_DIALOG__STORE_SNAPSHOTS_IN_DB: "Store snapshots in DB",
    COPY_STUDY_DIALOG__TABLE_FILTER: "Table Filter",
    CREATE_CACHE_DIALOG__CANCEL: "Cancel",
    CREATE_CACHE_DIALOG__RUN: "Run cache creation",
    CREATE_CACHE_DIALOG__RUN_SUCCESS: "Successfully generated cache creation job for dataset: {0}",
    CREATE_CACHE_DIALOG__TITLE: "Create cache for dataset: {0}",
    CREATE_RELEASE_DIALOG__CANCEL: "Cancel",
    CREATE_RELEASE_DIALOG__CREATE_RELEASE: "Create release - {0}",
    CREATE_RELEASE_DIALOG__CREATE: "Create",
    CREATE_RELEASE_DIALOG__ERROR: "Schema for dataset {0} failed to update",
    CREATE_RELEASE_DIALOG__RELEASE_NAME: "Release name",
    CSV_READER__CLICK_MESSAGE: "Click here to choose a file, or drop a file",
    CSV_READER__SUPPORTED_FILE_TYPES: "Supported file types: CSV",
    CSV_READER__UNSUPPORTED_FILE_TYPE: "Unsupported file type",
    DASHBOARD__BAR_CHART_TITLE: "Age at First Observation",
    DASHBOARD__BAR_CHART_TOOLTIP_FORMAT: "Age: {b}<br />Number of People: {c}",
    DASHBOARD__BAR_CHART_X_AXIS_NAME: "Age",
    DASHBOARD__BAR_CHART_Y_AXIS_NAME: "People",
    DASHBOARD__ERROR_MESSAGE: "Error occurred when fetching data characterization dashboard data",
    DASHBOARD__LOADER: "Loading Dashboard Reports",
    DASHBOARD__NOT_ENOUGH_PRIVILEGES: "Not enough privileges!",
    DASHBOARD__PIE_CHART_TITLE: "Gender",
    DATA_CHARACTERIZATION_REPORTS__REPORT_SELECTOR_LABEL: "Select Data Characterization Report",
    DATA_DENSITY__BOX_PLOT_TITLE: "Concepts Per Person",
    DATA_DENSITY__BOX_PLOT_X_AXIS_NAME: "Concept Type",
    DATA_DENSITY__BOX_PLOT_Y_AXIS_NAME: "Concepts Per Person",
    DATA_DENSITY__ERROR_MESSAGE: "Error occurred when fetching data characterization data density data",
    DATA_DENSITY__LOADER: "Loading Data Density Reports",
    DATA_DENSITY_RECORDS_PER_PERSON_CHART__NO_DATA: "Records Per Person",
    DATA_DENSITY_RECORDS_PER_PERSON_CHART__TITLE: "No data",
    DATA_DENSITY_RECORDS_PER_PERSON_CHART__X_AXIS_NAME: "Records Per Person",
    DATA_DENSITY_RECORDS_PER_PERSON_CHART__Y_AXIS_NAME: "Year",
    DATA_DENSITY_TOTAL_RECORDS_CHART__NO_DATA: "No data",
    DATA_DENSITY_TOTAL_RECORDS_CHART__TITLE: "Total Rows",
    DATA_DENSITY_TOTAL_RECORDS_CHART__X_AXIS_NAME: "Year",
    DATA_DENSITY_TOTAL_RECORDS_CHART__Y_AXIS_NAME: "# of Records",
    DATA_QUALITY_DIALOG__LATEST_JOB_CANCELLED: "Latest job cancelled",
    DATA_QUALITY_DIALOG__LATEST_JOB_FAILED: "Latest job failed",
    DATA_QUALITY_DIALOG__LATEST_JOB: "Latest Data Quality job is",
    DATA_QUALITY_DIALOG__LOAD_LATEST_RUN: "Loading Data Quality Latest Flow Run",
    DATA_QUALITY_DIALOG__NO_JOB_FOUND:
      "No Data Quality Job found for cohort, click Run Data Quality button to generate Data Quality results",
    DATA_QUALITY_DIALOG__RESULTS: "Data Quality results for Cohort",
    DATA_QUALITY_DIALOG__RUN_DATA_QUALITY: "Run Data Quality",
    DATA_QUALITY_HISTORY__DOMAIN_CONTINUITY: "Domain Continuity",
    DATA_QUALITY_HISTORY__ERROR:
      "Failed to retrieve data for charts. To report the error, please send an email to help@data4life.care.",
    DATASET_CARD__CHART_NAME: "Entity distribution",
    DATASET_CARD__DATA_MODEL: "Data model",
    DATASET_CARD__DATE: "Date",
    DATASET_CARD__NO_DATASET_SUMMARY: "No dataset summary available",
    DATASET_CARD__PATIENT_COUNT: "Total subjects",
    DATASET_CARD__VERSION: "Version",
    DATASET_DELETE_RESOURCE_DIALOG__CONFIRM: "Are you sure you want to delete the following file",
    DATASET_DELETE_RESOURCE_DIALOG__DELETE_FILE: "Delete file",
    DATASET_DELETE_RESOURCE_DIALOG__DELETE: "Delete",
    DATASET_DELETE_RESOURCE_DIALOG__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    DATASET_DELETE_RESOURCE_DIALOG__ERROR: "An error has occurred.",
    DATASET_FILTERS__AGE_RANGE: "Age range",
    DATASET_FILTERS__DOMAIN_REQUIREMENT: "Domain requirement",
    DATASET_FILTERS__ENTER_RANGE: "Enter range:",
    DATASET_FILTERS__MIN_CUMULATIVE_OBSERVATION: "Minimum cumulative observation",
    DATASET_FILTERS__OBSERVATION_YEAR_RANGE: "Observation year range",
    DATASET_FILTERS__RANGE_REQUIREMENT: "Range requirement",
    DATASET_FILTERS__SELECT_APPLICABLE_FILTERS: "Select applicable filters",
    DATASET_FILTERS__SELECT_REQUIREMENT: "Select requirement",
    DATASET_FILTERS__YEAR_RANGE: "Year range:",
    DATASET_RESOURCE_UPLOAD_CONFIRM_DIALOG__ADD_FILE: "Add file",
    DATASET_RESOURCE_UPLOAD_CONFIRM_DIALOG__ADD: "Add",
    DATASET_RESOURCE_UPLOAD_CONFIRM_DIALOG__CANCEL: "Cancel",
    DATASET_RESOURCE_UPLOAD_CONFIRM_DIALOG__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    DATASET_RESOURCE_UPLOAD_CONFIRM_DIALOG__ERROR: "An error has occurred.",
    DATASET_RESOURCE_UPLOAD_CONFIRM_DIALOG__FILE: "File",
    DATASET_RESOURCE_UPLOAD_CONFIRM_DIALOG__UNTITLED: "Untitled",
    DATASET_RESOURCES_DIALOG__ACTION: "Action",
    DATASET_RESOURCES_DIALOG__ADD_FILE: "Add file",
    DATASET_RESOURCES_DIALOG__CANCEL: "Cancel",
    DATASET_RESOURCES_DIALOG__DELETE: "Delete",
    DATASET_RESOURCES_DIALOG__DONE: "Done",
    DATASET_RESOURCES_DIALOG__DOWNLOAD: "Download",
    DATASET_RESOURCES_DIALOG__FILE: "File",
    DATASET_RESOURCES_DIALOG__FILENAME: "Filename",
    DATASET_RESOURCES_DIALOG__NO_FILE_AVAILABLE: "No file available",
    DATASET_RESOURCES_DIALOG__SIZE: "Size",
    DATASET_RESOURCES_DIALOG__SUCCESS: "File deleted successfully.",
    DATASET_RESOURCES_DIALOG__TITLE_1: "Resources",
    DATASET_RESOURCES_DIALOG__TITLE_2: "Untitled",
    DATASET_SELECTOR__SELECT_STUDY: "Select Study",
    DB__ADD_DATABASE: "Add database",
    DB__CODE: "Code",
    DB__NAME: "Name",
    DB__DATABASES: "Databases",
    DB__DELETE: "Delete",
    DB__DIALECT: "Dialect",
    DB__EDIT: "Edit",
    DB__HOST: "Host",
    DB__LOADER: "Loading databases",
    DB__NO_DATA: "No data available",
    DB__PORT: "Port",
    DEATH__BOX_PLOT_CHART_TITLE: "Age at Death",
    DEATH__BOX_PLOT_CHART_X_AXIS_NAME: "Gender",
    DEATH__BOX_PLOT_CHART_Y_AXIS_NAME: "Age at first occurrence",
    DEATH__ERROR_MESSAGE: "Error occurred when fetching data characterization death data",
    DEATH__LOADER: "Loading Death Reports",
    DEATH__PIE_CHART_TITLE: "Death By Type",
    DEATH_PREVALENCE_BY_MONTH_CHART__NO_DATA: "No data",
    DEATH_PREVALENCE_BY_MONTH_CHART__TITLE: "Death Prevalence by Month",
    DEATH_PREVALENCE_BY_MONTH_CHART__TOOLTIP_FORMAT: "Date: {b}<br />Prevalence Per 1000 People: {c}%",
    DEATH_PREVALENCE_BY_MONTH_CHART__X_AXIS_NAME: "Date",
    DEATH_PREVALENCE_BY_MONTH_CHART__Y_AXIS_FORMAT: "{value}",
    DEATH_PREVALENCE_BY_MONTH_CHART__Y_AXIS_NAME: "Prevalence Per 1000 People",
    DELETE_ATTRIBUTE_DIALOG__ATTRIBUTES: "Attribute",
    DELETE_ATTRIBUTE_DIALOG__CANCEL: "Cancel",
    DELETE_ATTRIBUTE_DIALOG__CONFIRMATION: "Are you sure you want to delete the following",
    DELETE_ATTRIBUTE_DIALOG__DELETE_ATTRIBUTE: "Delete Attribute",
    DELETE_ATTRIBUTE_DIALOG__DELETE: "Delete",
    DELETE_ATTRIBUTE_DIALOG__SUCCESS: "Attribute Config deleted successfully.",
    DELETE_DB_DIALOG___DATABASE: "Database",
    DELETE_DB_DIALOG__CANCEL: "Cancel",
    DELETE_DB_DIALOG__CONFIRMATION: "Are you sure you want to delete the following",
    DELETE_DB_DIALOG__DELETE_DATABASE: "Delete database",
    DELETE_DB_DIALOG__DELETE_SUCCESS: "Database deleted successfully",
    DELETE_DB_DIALOG__DELETE: "Delete",
    DELETE_FLOW_DIALOG__CANCEL: "Cancel",
    DELETE_FLOW_DIALOG__CONFIRM_1: "Are you sure you want to permanently delete the following",
    DELETE_FLOW_DIALOG__CONFIRM_2: "flow",
    DELETE_FLOW_DIALOG__CONFIRM_3: "This action cannot be undone. Deleted data cannot be recovered.",
    DELETE_FLOW_DIALOG__CONFIRM_4: "Type the flow name to confirm.",
    DELETE_FLOW_DIALOG__CONFIRM_DELETION: "Confirm deletion",
    DELETE_FLOW_DIALOG__DELETE: "Delete flow",
    DELETE_FLOW_DIALOG__ENTER_EXACT_FLOW_NAME: "Enter the exact flow name",
    DELETE_FLOW_DIALOG__ENTER_FLOW_NAME: "Enter flow name",
    DELETE_FLOW_DIALOG__ERROR: "Flow {0} failed to delete",
    DELETE_NOTEBOOK_DIALOG__CANCEL: "Cancel",
    DELETE_NOTEBOOK_DIALOG__CONFIRM: "Are you sure you want to delete the following notebook",
    DELETE_NOTEBOOK_DIALOG__DELETE_NOTEBOOK: "Delete notebook",
    DELETE_NOTEBOOK_DIALOG__DELETE: "Delete",
    DELETE_STUDY_DIALOG__CANCEL: "Cancel",
    DELETE_STUDY_DIALOG__CONFIRM: "Are you sure you want to delete this dataset",
    DELETE_STUDY_DIALOG__DELETE_DATASET: "Delete dataset",
    DELETE_STUDY_DIALOG__ERROR: "Study {0} failed to delete",
    DELETE_STUDY_DIALOG__YES_DELETE: "Yes, delete",
    DELETE_TAG_DIALOG__CANCEL: "Cancel",
    DELETE_TAG_DIALOG__CONFIRM_1: "Are you sure you want to delete the following",
    DELETE_TAG_DIALOG__CONFIRM_2: "Tag",
    DELETE_TAG_DIALOG__DELETE_TAG: "Delete Tag",
    DELETE_TAG_DIALOG__DELETE: "Delete",
    DELETE_TAG_DIALOG__SUCCESS: "Tag Config deleted successfully.",
    DELETE_USER_DIALOG__CANCEL: "Cancel",
    DELETE_USER_DIALOG__CONFIRM: "Are you sure you want to delete this account",
    DELETE_USER_DIALOG__DELETE_USER: "Delete user",
    DELETE_USER_DIALOG__DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    DELETE_USER_DIALOG__ERROR: "An error has occurred.",
    DELETE_USER_DIALOG__YES: "Yes, delete",
    DEMO_SETUP__DESCRIPTION: "Please execute the following three steps to setup the demo dataset:",
    DEMO_SETUP__RESTART: "Please restart all services",
    DEMO_SETUP__RUN: "Run",
    DEMO_SETUP__SETUP_DEMO_TITLE: "Demo setup",
    DEMO_SETUP__SETUP_DEMO_DATASET: "Setup demo dataset",
    DEMO_SETUP__SETUP_DEMO_DB: "Setup demo database",
    DOMAIN_CONTINUITY_CHART__NO_DATA: "No data",
    DOMAIN_CONTINUITY_CHART__X_AXIS_NAME: "CDM Release Date",
    DOMAIN_CONTINUITY_CHART__Y_AXIS_NAME: "No. of records",
    DOWNLOAD_DATA_BUTTONS__CATEGORY: "CATEGORY",
    DOWNLOAD_DATA_BUTTONS__CONTEXT: "CONTEXT",
    DOWNLOAD_DATA_BUTTONS__DESCRIPTION: "DESCRIPTION",
    DOWNLOAD_DATA_BUTTONS__DOWNLOAD_CSV: "Download CSV",
    DOWNLOAD_DATA_BUTTONS__DOWNLOAD_JSON: "Download JSON",
    DOWNLOAD_DATA_BUTTONS__LEVEL: "LEVEL",
    DOWNLOAD_DATA_BUTTONS__NOTES: "NOTES",
    DOWNLOAD_DATA_BUTTONS__NUMVIOLATEDROWS: "NUMVIOLATEDROWS",
    DOWNLOAD_DATA_BUTTONS__STATUS: "STATUS",
    DOWNLOAD_DATA_BUTTONS__SUBCATEGORY: "SUBCATEGORY",
    DOWNLOAD_DATA_BUTTONS__TABLE: "TABLE",
    DOWNLOAD_DATA_BUTTONS__VIOLATEDROWS: "%VIOLATEDROWS",
    DQD__DATA_CHARACTERIZATION: "Data Characterization",
    DQD__DETAIL: "Detail",
    DQD__JOB_STATUS: "Job Status",
    DQD__LOADER: "Loading DQD Jobs",
    DQD__OVERVIEW: "Overview",
    DQD__REFRESH: "Refresh Table",
    DQD__SELECT: "Select a Study to view {0} results",
    DQD_COMBINED_RESULTS__DETAIL: "Detail",
    DQD_COMBINED_RESULTS__LOADER_1: "Loading DQD Overview",
    DQD_COMBINED_RESULTS__LOADER_2: "Loading DQD Results",
    DQD_COMBINED_RESULTS__LOADER_MESSAGE_1: "Error loading DQD Overview",
    DQD_COMBINED_RESULTS__LOADER_MESSAGE_2: "Error loading DQD Results",
    DQD_COMBINED_RESULTS__OVERVIEW: "Overview",
    DQD_JOB_RESULTS__ERROR_DQD_OVERVIEW: "Error loading DQD Overview: {0}",
    DQD_JOB_RESULTS__ERROR_DQD_RESULTS: "Error loading DQD Results: {0}",
    DQD_JOB_RESULTS__INCORRECT_TABLETYPE: "Incorrect tableType supplied",
    DQD_JOB_RESULTS__LATEST_JOB_CANCELLED: "Latest job cancelled",
    DQD_JOB_RESULTS__LATEST_JOB_FAILED: "Latest job failed",
    DQD_JOB_RESULTS__LOADER_1: "Latest Data Characterization job is {0}",
    DQD_JOB_RESULTS__LOADER_2: "Latest Data Quality job is {0}",
    DQD_JOB_RESULTS__LOADER_3: "Loading Data Quality Latest Flow Run",
    DQD_JOB_RESULTS__LOADER_4: "Loading DQD Overview",
    DQD_JOB_RESULTS__LOADER_5: "Loading DQD Results",
    DQD_JOB_RESULTS__NO_JOB_FOUND: "No job found",
    DQD_TABLE__COLUMN_CATEGORY_HEADER: "CATEGORY",
    DQD_TABLE__COLUMN_CONTEXT_HEADER: "CONTEXT",
    DQD_TABLE__COLUMN_DESCRIPTION_HEADER: "DESCRIPTION",
    DQD_TABLE__COLUMN_IS_ERROR_HEADER: "Is Error",
    DQD_TABLE__COLUMN_LEVEL_HEADER: "LEVEL",
    DQD_TABLE__COLUMN_NOT_APPLICABLE_HEADER: "Not Applicable",
    DQD_TABLE__COLUMN_NOTES_HEADER: "NOTES",
    DQD_TABLE__COLUMN_STATUS_HEADER: "STATUS",
    DQD_TABLE__COLUMN_SUBCATEGORY_HEADER: "SUBCATEGORY",
    DQD_TABLE__COLUMN_TABLE_HEADER: "TABLE",
    DQD_TABLE__COLUMN_VIOLATED_ROWS_HEADER: "%VIOLATEDROWS",
    DQD_TABLE__NO: "No",
    DQD_TABLE__RENDER_DETAIL_PANE_CHECK_ID: "Check id",
    DQD_TABLE__RENDER_DETAIL_PANE_CONCEPT_ID: "Concept id",
    DQD_TABLE__RENDER_DETAIL_PANE_DESCRIPTION: "Description",
    DQD_TABLE__RENDER_DETAIL_PANE_ERROR_MESSAGE: "Error Message",
    DQD_TABLE__RENDER_DETAIL_PANE_EXECUTION_TIME: "Execution Time",
    DQD_TABLE__RENDER_DETAIL_PANE_FIELD: "Field",
    DQD_TABLE__RENDER_DETAIL_PANE_IS_ERROR: "Is Error",
    DQD_TABLE__RENDER_DETAIL_PANE_LEVEL: "Level",
    DQD_TABLE__RENDER_DETAIL_PANE_NAME: "Name",
    DQD_TABLE__RENDER_DETAIL_PANE_NOT_APPLICABLE: "Not Applicable",
    DQD_TABLE__RENDER_DETAIL_PANE_NOTES: "Notes",
    DQD_TABLE__RENDER_DETAIL_PANE_NUMBER_DENOMINATOR: "# Denominator Rows",
    DQD_TABLE__RENDER_DETAIL_PANE_NUMBER_ROWS_VIOLATED: "# Rows Violated",
    DQD_TABLE__RENDER_DETAIL_PANE_PERCENTAGE_ROWS_VIOLATED: "% Rows Violated",
    DQD_TABLE__RENDER_DETAIL_PANE_SQL_QUERY: "SQL query",
    DQD_TABLE__RENDER_DETAIL_PANE_TABLE: "Table",
    DQD_TABLE__RENDER_DETAIL_PANE_UNIT_CONCEPT_ID: "Unit Concept id",
    DQD_TABLE__YES: "Yes",
    DRILLDOWN_PREVALENCE_BY_MONTH_CHART__NO_DATA: "No data",
    DRILLDOWN_PREVALENCE_BY_MONTH_CHART__TITLE: "Prevalence by Month",
    DRILLDOWN_PREVALENCE_BY_MONTH_CHART__TOOLTIP_FORMAT: "Date: {b}<br />Prevalence per 1000 People: {c}",
    DRILLDOWN_PREVALENCE_BY_MONTH_CHART__X_AXIS_NAME: "Date",
    DRILLDOWN_PREVALENCE_BY_MONTH_CHART__Y_AXIS_FORMAT: "{value}",
    DRILLDOWN_PREVALENCE_BY_MONTH_CHART__Y_AXIS_NAME: "Prevalence per 1000 People",
    DRILLDOWN_TRELLIS_CHART__TITLE: "Prevalence",
    DRILLDOWN_TRELLIS_CHART__TRELLIS_BOTTOM_LABEL: "Years of Observation",
    DRILLDOWN_TRELLIS_CHART__TRELLIS_TOP_LABEL: "Age Decile",
    DRILLDOWN_TRELLIS_CHART__Y_AXIS_PREVALENCE_PER_1000_PEOPLE: "Prevalence per 1000 People",
    EDIT_DB_CREDENTIAL_DIALOG__CANCEL: "Cancel",
    EDIT_DB_CREDENTIAL_DIALOG__CREDENTIALS: "Credentials",
    EDIT_DB_CREDENTIAL_DIALOG__DATABASE_CODE: "Database Code",
    EDIT_DB_CREDENTIAL_DIALOG__EDIT_DATABASE_CREDENTIALS: "Edit database credentials",
    EDIT_DB_CREDENTIAL_DIALOG__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    EDIT_DB_CREDENTIAL_DIALOG__ERROR: "An error has occurred.",
    EDIT_DB_CREDENTIAL_DIALOG__PASSWORD: "Password",
    EDIT_DB_CREDENTIAL_DIALOG__PRIVILEGE: "Privilege",
    EDIT_DB_CREDENTIAL_DIALOG__SERVICE: "Service",
    EDIT_DB_CREDENTIAL_DIALOG__SUCCESS: "Database {0}} credentials updated",
    EDIT_DB_CREDENTIAL_DIALOG__UPDATE: "Update",
    EDIT_DB_CREDENTIAL_DIALOG__USERNAME: "Username",
    EDIT_TENANT_ROLE_DIALOG__CANCEL: "Cancel",
    EDIT_TENANT_ROLE_DIALOG__EDIT: "Edit roles",
    EDIT_TENANT_ROLE_DIALOG__ROLES: "Roles",
    EDIT_TENANT_ROLE_DIALOG__SAVE: "Save",
    EDIT_TITLE_DIALOG__ALREADY_EXISTS: "The notebook title already exists. Please enter a different title.",
    EDIT_TITLE_DIALOG__CANCEL: "Cancel",
    EDIT_TITLE_DIALOG__EDIT_NOTEBOOK_TITLE: "Edit Notebook Title",
    EDIT_TITLE_DIALOG__NOTEBOOK_TITLE: "Notebook Title",
    EDIT_TITLE_DIALOG__SAVE: "Save",
    EMPTY_NOTEBOOK__ADD: "Add New Notebook",
    EMPTY_NOTEBOOK__IMPORT: "Import Notebook",
    EMPTY_NOTEBOOK__TITLE: "ALP Notebook",
    EXECUTE_FLOW_DIALOG__CANCEL: "Cancel",
    EXECUTE_FLOW_DIALOG__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    EXECUTE_FLOW_DIALOG__ERROR: "An error has occurred.",
    EXECUTE_FLOW_DIALOG__EXECUTE_FLOW: "Execute flow",
    EXECUTE_FLOW_DIALOG__EXECUTE: "Execute",
    EXECUTE_FLOW_DIALOG__FLOW_PARAMETERS: "Flow parameters",
    EXECUTE_FLOWDIALOG__SCHEDULE: "Schedule",
    EXECUTE_FLOWDIALOG__RUN_IMMEDIATELY: "Run immediately",
    EXECUTE_FLOWDIALOG__RUN_SCHEDULE: "Run on schedule",
    EXECUTE_FLOWDIALOG__EMPTY_SCHEDULE_ERROR: "Schedule is not specified",
    EXECUTE_FLOWDIALOG__PAST_SCHEDULE_ERROR: "Past schedule is not allowed",
    EXECUTE_FLOWDIALOG__INVALID_SCHEDULE_ERROR: "Invalid schedule format",
    EXECUTE_FLOW_DIALOG__FLOW_RUN: "Flow run",
    EXECUTE_FLOW_DIALOG__NAME: "Name",
    EXECUTE_FLOW_DIALOG__REQUIRED: "This is required",
    EXPORT_MAPPING_DIALOG__TITLE: "Export mappings to database",
    EXPORT_MAPPING_DIALOG__FORM_TITLE: "Concept Mapping Configuration",
    EXPORT_MAPPING_DIALOG__SOURCE_VOCABULARY_ID: "SOURCE VOCABULARY ID",
    EXPORT_MAPPING_DIALOG__HELPER_TEXT:
      "id should be more than 100 so that it can be easily identified as a non-OMOP vocabulary",
    EXPORT_MAPPING_DIALOG__REQUIRED: "This is required",
    EXPORT_MAPPING_DIALOG__NO_DATA: "No data available",
    FEATURE__CDM_DOWNLOAD: "CDM Download",
    FEATURE__COHORT: "Cohort",
    FEATURE__CONCEPTS: "Concepts",
    FEATURE__DATAFLOW: "Dataflow",
    FEATURE__DATASET_FILTER: "Dataset filter",
    FEATURE__DATASET_SEARCH: "Dataset search",
    FEATURE__FHIR_SERVER: "Fhir server",
    FEATURE__ERROR_DESCRIPTION: "Please try again. To report the error, please send an email to help@data4life.care.",
    FEATURE__ERROR: "An error has occurred.",
    FEATURE__FEATURE_FLAGS: "Feature flags",
    FEATURE__NOTEBOOKS: "Notebooks",
    FEATURE__PATIENT_ANALYTICS: "Patient Analytics",
    FEATURE__SAVE: "Save",
    FEATURE__SUCCESS: "Changes saved",
    FEATURE__TERMINOLOGY: "Terminology",
    HEADER__DELETE: "Delete",
    HEADER__DOWNLOAD: "Download source code",
    HEADER__ERROR_DELETE: "An error has occurred while deleting notebook",
    HEADER__ERROR_SAVED: "An error has occurred while saving notebook",
    HEADER__EXPORT: "Export Notebook",
    HEADER__FILE_DELETED: "File Deleted",
    HEADER__IMPORT_ERROR: "An error has occurred. Please import Jupyter files(.ipynb) only.",
    HEADER__IMPORT: "Import Notebook",
    HEADER__NEW: "New Notebook",
    HEADER__RENAME_ERROR: "An error has occurred. Please try again.",
    HEADER__RENAME_SUCCESS: "Changes saved",
    HEADER__SAVE: "Save",
    HEADER__SAVED: "Changes saved",
    HEADER__SHARE: "Share notebook",
    HISTORICAL_DATA_QUALITY_CHART__NO_DATA: "No data",
    HISTORICAL_DATA_QUALITY_CHART__TITLE: "Historical data quality",
    HISTORICAL_DATA_QUALITY_CHART__X_AXIS_NAME: "CDM Release Date",
    HISTORICAL_DATA_QUALITY_CHART__Y_AXIS_NAME: "Checks Failed",
    HISTORICAL_DATA_QUALITY_MULTI_SERIES_CHART__NO_DATA: "No data",
    HISTORICAL_DATA_QUALITY_MULTI_SERIES_CHART__TITLE: "Historical data quality by {0}",
    HISTORICAL_DATA_QUALITY_MULTI_SERIES_CHART__X_AXIS_NAME: "CDM Release Date",
    HISTORICAL_DATA_QUALITY_MULTI_SERIES_CHART__Y_AXIS_NAME: "Checks Failed",
    HISTORY_TABLE__CANCEL_JOB: "Cancel Job",
    HISTORY_TABLE__VIEW_DETAIL: "View detail",
    HOME__DESCRIPTION:
      "Our vision is a world where health data is comprehensively, digitally, and securely available for research and directly impacts the prevention, diagnosis, and treatment of diseases.",
    IMPORT_DIALOG__ADDITIONAL_INFO_COLUMN: "Additional info column",
    IMPORT_DIALOG__CANCEL: "Cancel",
    IMPORT_DIALOG__COLUMN_MAPPING: "Column Mapping",
    IMPORT_DIALOG__IMPORT: "Import",
    IMPORT_DIALOG__SOURCE_CODE_COLUMN: "Source code colum",
    IMPORT_DIALOG__SOURCE_CODE_NAME: "Source name column",
    IMPORT_DIALOG__SOURCE_FREQUENCY_COLUMN: "Source frequency column",
    IMPORT_DIALOG__SHOW_SOURCE_DOMAIN_COLUMN: "Show source domain column selection",
    IMPORT_DIALOG__SOURCE_DOMAIN_COLUMN: "Source domain column",
    INFORMATION___DOWNLOAD_CDM_DATA: "Download CDM Data",
    INFORMATION__DATABASE_NAME_UNDEFINED: "Error: Schema Name or Database Name is undefined ",
    INFORMATION__DATASET: "Dataset",
    INFORMATION__DOWNLOAD_FILE: "Download file",
    INFORMATION__DOWNLOAD: "Download",
    INFORMATION__FEEDBACK_ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    INFORMATION__FEEDBACK_ERROR_MESSAGE: "An error has occurred.",
    INFORMATION__FEEDBACK_MESSAGE: "Request has been sent",
    INFORMATION__FILENAME: "Filename",
    INFORMATION__FILES: "Files",
    INFORMATION__HOW_TO_ACCESS: "How to access the data",
    INFORMATION__METADATA: "Metadata",
    INFORMATION__NO_DATASET_DESCRIPTION: "No dataset description available",
    INFORMATION__NO_FILE_AVAILABLE: "No file available",
    INFORMATION__OVERVIEW: "Overview",
    INFORMATION__PATIENT_DATA: "Patient Data",
    INFORMATION__PENDING_APPROVAL: "Pending approval",
    INFORMATION__REQUEST_ACCESS: "Request access",
    INFORMATION__RESOURCE_TYPE: "Resource Type",
    INFORMATION__RESULTS: "Results",
    INFORMATION__SCHEMA_NAME_UNDEFINED: "Error: Schema Name is undefined ",
    INFORMATION__SIZE: "Size",
    INFORMATION__TAB_DASHBOARD: "Dashboard",
    INFORMATION__TAB_DATA_CHARACTERIZATION: "Data Characterization",
    INFORMATION__TAB_DATA_QUALITY: "Data Quality",
    INFORMATION__TAB_DATASET_INFO: "Dataset Info",
    INFORMATION__TAB_HISTORY: "History",
    INFORMATION__TAGS: "Tags",
    INFORMATION__UNTITLED: "Untitled",
    JOB_DIALOG__CANCEL: "Cancel",
    JOB_DIALOG__COMMENT: "Comment",
    JOB_DIALOG__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    JOB_DIALOG__ERROR: "An error has occurred when trying to create a {0} Job",
    JOB_DIALOG__GENERATE_DATA_CHARACTERIZATION: "Generate Data Characterization",
    JOB_DIALOG__GENERATE_DATA_QUALITY: "Generate Data Quality",
    JOB_DIALOG__GENERATE: "Generate",
    JOB_DIALOG__GENERATING_JOB: "Generating Job",
    JOB_DIALOG__SUCCESS: "{0} job successfully started.",
    JOB_RUN_BUTTONS__RUN_DATA_CHARACTERIZATION: "Run Data Characterization",
    JOB_RUN_BUTTONS__RUN_DATA_QUALITY: "Run Data Quality",
    JOB_RUN_BUTTONS__NO_DATA_QUALITY_JOB_TOOLTIP: "Data Quality job not installed",
    JOB_RUNS_FILTER__CLEAR_SELECTIONS: "Clear selections",
    JOB_RUNS_FILTER__REFRESH: "Refresh",
    JOB_RUNS_TABLE__ACTION: "Action",
    JOB_RUNS_TABLE__CANCEL: "Cancel Job",
    JOB_RUNS_TABLE__COMPLETED_TIME: "Completed time",
    JOB_RUNS_TABLE__CREATED_TIME: "Created time",
    JOB_RUNS_TABLE__DATA_CHARACTERIZATION_SCHEMA: "Data Characterization Schema",
    JOB_RUNS_TABLE__DATASETS: "Datasets",
    JOB_RUNS_TABLE__LOGS: "Logs",
    JOB_RUNS_TABLE__NAME: "Name",
    JOB_RUNS_TABLE__NO_JOBS_FOUND: "No Jobs Found",
    JOB_RUNS_TABLE__NOT_AVAILABLE: "Not available",
    JOB_RUNS_TABLE__RESULTS: "Results",
    JOB_RUNS_TABLE__STATE: "State",
    JOB_RUNS_TABLE__TAG: "Tag",
    JOB_RUNS_TABLE__VIEW: "View",
    JOB_TABLE__DELETE: "Delete",
    JOB_TABLE__END_DATE: "End Date",
    JOB_TABLE__EXECUTION_ID: "Execution ID",
    JOB_TABLE__JOB_NAME: "Job Name",
    JOB_TABLE__NO_DATA: "No data available",
    JOB_TABLE__START_DATE: "Start Date",
    JOB_TABLE__UPDATE_DEPLOYMENT: "Update deployment",
    PLUGIN_TABLE__PLUGIN_NAME: "Plugin Name",
    PLUGIN_TABLE__DELETE: "Delete",
    PLUGIN_TABLE__END_DATE: "End Date",
    PLUGIN_TABLE__EXECUTION_ID: "Execution ID",
    PLUGIN_TABLE__START_DATE: "Start Date",
    PLUGIN_TABLE__UPDATE_DEPLOYMENT: "Update deployment",
    PLUGIN_TABLE__NO_DATA: "No Data available",
    JOBS__BACK: "Back to Jobs",
    JOBS__DATA_QUALITY_ANALYSIS: "Data Quality Analysis",
    JOBS__JOBS: "Jobs",
    JOBS__LOADER: "Loading Jobs",
    JOBS__MANAGE_DATAFLOWS: "Manage Dataflows",
    JOBS__UPLOAD: "Upload Job",
    LEGAL_MENU__IMPRINT: "Imprint",
    LEGAL_MENU__MENU: "Menu",
    LEGAL_MENU__PRIVACY_POLICY: "Privacy Policy",
    LEGAL_MENU__TERMS_OF_USE: "Terms of Use",
    MAPPING_TABLE__CONCEPT_ID: "Concept ID",
    MAPPING_TABLE__CONCEPT_NAME: "Concept Name",
    MAPPING_TABLE__DESCRIPTION: "Description",
    MAPPING_TABLE__DOMAIN_ID: "Domain",
    MAPPING_TABLE__FREQUENCY: "Frequency",
    MAPPING_TABLE__VOCABULARY: "Vocabulary",
    MAPPING_TABLE__NAME: "Name",
    MAPPING_TABLE__POPULATE_CONCEPTS: "Populate concepts",
    MAPPING_TABLE__SOURCE: "Source",
    MAPPING_TABLE__STATUS: "Status",
    MENU_NAV__DATASET: "Dataset",
    MENU_NAV__UNTITLED: "Untitled",
    METADATA_FORM__REQUIRED: "This is required",
    MORE_ACTION_BUTTON__CHANGE_PASSWORD: "Change password",
    NO_ACCESS__ACCESS_DENIED: "Access denied.",
    NO_ACCESS__INFO: "Please check with your administrator to request access",
    NOTEBOOK_SELECT__SHARED: "(Shared)",
    OBSERVATION_PERIOD__BAR_CHART_1_TITLE: "Age at First Observation",
    OBSERVATION_PERIOD__BAR_CHART_1_TOOLTIP_FORMAT: "Age: {b}<br />Number of People: {c}",
    OBSERVATION_PERIOD__BAR_CHART_1_X_AXIS_NAME: "Age",
    OBSERVATION_PERIOD__BAR_CHART_1_Y_AXIS_NAME: "People",
    OBSERVATION_PERIOD__BAR_CHART_2_TITLE: "Observation Length",
    OBSERVATION_PERIOD__BAR_CHART_2_TOOLTIP_FORMAT: "Year: {b}<br />People: {c}",
    OBSERVATION_PERIOD__BAR_CHART_2_X_AXIS_NAME: "Years",
    OBSERVATION_PERIOD__BAR_CHART_2_Y_AXIS_NAME: "People",
    OBSERVATION_PERIOD__BAR_CHART_3_TITLE: "Persons With Continuous Observation By Year",
    OBSERVATION_PERIOD__BAR_CHART_3_TOOLTIP_FORMAT: "Year: {b}<br />People: {c}",
    OBSERVATION_PERIOD__BAR_CHART_3_X_AXIS_NAME: "Years",
    OBSERVATION_PERIOD__BAR_CHART_3_Y_AXIS_NAME: "People",
    OBSERVATION_PERIOD__BOX_PLOT_CHART_1_TITLE: "Age by Death",
    OBSERVATION_PERIOD__BOX_PLOT_CHART_1_X_AXIS_NAME: "Gender",
    OBSERVATION_PERIOD__BOX_PLOT_CHART_1_Y_AXIS_NAME: "Age",
    OBSERVATION_PERIOD__BOX_PLOT_CHART_2_TITLE: "Duration by Gender",
    OBSERVATION_PERIOD__BOX_PLOT_CHART_2_X_AXIS_NAME: "Gender",
    OBSERVATION_PERIOD__BOX_PLOT_CHART_2_Y_AXIS_NAME: "Years",
    OBSERVATION_PERIOD__BOX_PLOT_CHART_3_TITLE: "Duration by Age Decile",
    OBSERVATION_PERIOD__BOX_PLOT_CHART_3_X_AXIS_NAME: "Age Decile",
    OBSERVATION_PERIOD__BOX_PLOT_CHART_3_Y_AXIS_NAME: "Years",
    OBSERVATION_PERIOD__ERROR_MESSAGE: "Error occurred when fetching data characterization observationPeriod data",
    OBSERVATION_PERIOD__LOADER: "Loading ObservationPeriod Reports",
    OBSERVATION_PERIOD__PIE_CHART_TITLE: "Observation Periods per Person",
    OBSERVATION_PERIOD_CUMULATIVE_DURATION_CHART__NO_DATA: "No data",
    OBSERVATION_PERIOD_CUMULATIVE_DURATION_CHART__TITLE: "Cumulative Observation",
    OBSERVATION_PERIOD_CUMULATIVE_DURATION_CHART__TOOLTIP_FORMAT: "Years: {b}<br />Percent of Population: {c}%",
    OBSERVATION_PERIOD_CUMULATIVE_DURATION_CHART__X_AXIS_NAME: "Years",
    OBSERVATION_PERIOD_CUMULATIVE_DURATION_CHART__Y_AXIS_FORMAT: "{value}%",
    OBSERVATION_PERIOD_CUMULATIVE_DURATION_CHART__Y_AXIS_NAME: "Percent of Population",
    OBSERVATION_PERIOD_OBSERVED_BY_MONTH_CHART__NO_DATA: "No data",
    OBSERVATION_PERIOD_OBSERVED_BY_MONTH_CHART__TITLE: "Persons With Continuous Observation By Month",
    OBSERVATION_PERIOD_OBSERVED_BY_MONTH_CHART__TOOLTIP_FORMAT: "Date: {b}<br />People: {c}",
    OBSERVATION_PERIOD_OBSERVED_BY_MONTH_CHART__X_AXIS_NAME: "Date",
    OBSERVATION_PERIOD_OBSERVED_BY_MONTH_CHART__Y_AXIS_NAME: "People",
    OIDC_CALLBACK_SUCCESS__LOADER: "Authentication completed. Redirecting...",
    OIDC_ERROR__MESSAGE: "Error occurred while authenticating",
    OIDC_SESSION_LOST__BUTTON: "Logout",
    OVERVIEW__CLEAR_AND_IMPORT: "Clear and Import another file",
    OVERVIEW__CONCEPT_ID: "Concept Id",
    OVERVIEW__CONCEPT_MAPPING: "Concept Mapping",
    OVERVIEW__CONCEPT_NAME: "Concept Name",
    OVERVIEW__DESCRIPTION: "Description",
    OVERVIEW__DOMAIN: "Domain",
    OVERVIEW__DOWNLOAD_CSV: "Download CSV",
    OVERVIEW__SAVE_MAPPINGS: "Save mappings",
    OVERVIEW__FREQUENCY: "Frequency",
    OVERVIEW__NAME: "Name",
    OVERVIEW__NO_DATASET: "No dataset available",
    OVERVIEW__REFERENCE_CONCEPTS: "Reference concepts from dataset",
    OVERVIEW__SOURCE: "Source",
    OVERVIEW_TABLE__CATEGORY_COMPLETENESS: "Completeness",
    OVERVIEW_TABLE__CATEGORY_CONFORMANCE: "Conformance",
    OVERVIEW_TABLE__CATEGORY_PLAUSIBILITY: "Plausibility",
    OVERVIEW_TABLE__CATEGORY_TOTAL: "Total",
    OVERVIEW_TABLE__FAIL: "Fail",
    OVERVIEW_TABLE__NOTE_1: "{0} out of {1} passed checks are Not Applicable, due to empty tables or fields.",
    OVERVIEW_TABLE__NOTE_2: "{0} out of {1} failed checks are SQL errors.",
    OVERVIEW_TABLE__NOTE_3: "Corrected pass percentage for NA and Errors: {0} ({1}/{2}).",
    OVERVIEW_TABLE__PASS: "Pass",
    OVERVIEW_TABLE__PCT_PASS: "% Pass",
    OVERVIEW_TABLE__TOTAL: "Total",
    OVERVIEW_TABLE__VALIDATION: "Validation",
    OVERVIEW_TABLE__VERIFICATION: "Verification",
    OVERVIEW_DESCRIPTION__TITLE: "Overview description",
    OVERVIEW_DESCRIPTION__REVERT_CHANGES: "Revert changes",
    OVERVIEW_DESCRIPTION__SAVE: "Save",
    OVERVIEW_DESCRIPTION__SUCCESS_MESSAGE: "Changes saved",
    PERMISSIONS_DIALOG__ACCESS: "Access",
    PERMISSIONS_DIALOG__CLOSE: "Close",
    PERMISSIONS_DIALOG__ERROR: "Error while saving. {0}",
    PERMISSIONS_DIALOG__PERMISSIONS: "Permissions",
    PERMISSIONS_DIALOG__REQUEST: "Request",
    PERMISSIONS_DIALOG__SAVE: "Save",
    PERMISSIONS_DIALOG__SUCCESS: "Saved successfully",
    PERSON__BAR_CHART_TITLE: "Year of Birth",
    PERSON__BAR_CHART_TOOLTIP_FORMAT: "Year: {b}<br /># of Persons: {c}",
    PERSON__BAR_CHART_X_AXIS_NAME: "Year",
    PERSON__BAR_CHART_Y_AXIS_NAME: "# of Persons",
    PERSON__ERROR_MESSAGE: "Error occurred when fetching data characterization person data",
    PERSON__LOADER: "Loading Person Reports",
    PERSON__PIE_CHART_1_TITLE: "Gender",
    PERSON__PIE_CHART_2_TITLE: "Race",
    PERSON__PIE_CHART_3_TITLE: "Ethnicity",
    PORTAL_SWITCHER__ADMIN: "Admin",
    PORTAL_SWITCHER__RESEARCHER: "Researcher",
    PUBLIC__NAVIGATION_LEGAL: "Legal",
    PUBLIC__NAVIGATION_LOGIN: "Login",
    PUBLIC_DATASET_CARD__DATA_MODEL: "Data model",
    PUBLIC_DATASET_CARD__DATE: "Date",
    PUBLIC_DATASET_CARD__NO_DATASET_SUMMARY: "No dataset summary available",
    PUBLIC_DATASET_CARD__PATIENT_COUNT: "Total subjects",
    PUBLIC_DATASET_CARD__VERSION: "Version",
    PUBLIC_INFORMATION__DATASET: "Dataset",
    PUBLIC_INFORMATION__METADATA: "Metadata",
    PUBLIC_INFORMATION__NO_DATASET_DESCRIPTION: "No dataset description available",
    PUBLIC_INFORMATION__RESOURCE_TYPE: "Resource Type",
    PUBLIC_INFORMATION__TAB_DATASET_INFO: "Dataset Info",
    PUBLIC_INFORMATION__TAGS: "Tags",
    PUBLIC_INFORMATION__UNTITLED: "Untitled",
    PUBLIC_OVERVIEW__DATASET_OVERVIEW: "Dataset overview",
    PUBLIC_STUDY_MENU__DATASET_INFORMATION: "Dataset information",
    PUBLIC_STUDY_OVERVIEW_NAV__DATASET: "Dataset",
    RELEASE_SELECTOR__RELEASE_SELECTION: "Release Selection",
    REQUEST_PANEL__APPROVE: "Approve",
    REQUEST_PANEL__EMAIL: "Email",
    REQUEST_PANEL__NO_DATA: "No data available",
    REQUEST_PANEL__REJECT: "Reject",
    REQUEST_PANEL__REQUESTED: "Requested on",
    REQUEST_PANEL__REQUESTS: "Requests",
    REQUEST_PANEL__SELECT_ACTION: "Select action",
    RESULTS_DIALOG__RESULTS: "Results for dataset: {0} ({1})",
    ROLES_SELECT__EDIT: "Edit role",
    SAMPLE_RESEARCHER_STUDY__GET_JWT_TOKEN: "Get JWT token",
    SAMPLE_RESEARCHER_STUDY__HELLO: "Hello {0}",
    SAMPLE_RESEARCHER_STUDY__STUDY: "Study {0}",
    SAMPLE_RESEARCHER_STUDY__TITLE: "[Built-in] Researcher study plugin page",
    SAVE_ATTRIBUTE_DIALOG__ADD: "Add Attribute",
    SAVE_ATTRIBUTE_DIALOG__ATTRIBUTE_ID: "Attribute Id",
    SAVE_ATTRIBUTE_DIALOG__ATTRIBUTE_NAME: "Attribute Name",
    SAVE_ATTRIBUTE_DIALOG__CANCEL: "Cancel",
    SAVE_ATTRIBUTE_DIALOG__CATEGORY: "Category",
    SAVE_ATTRIBUTE_DIALOG__DATATYPE: "Datatype",
    SAVE_ATTRIBUTE_DIALOG__EDIT: "Edit Attribute",
    SAVE_ATTRIBUTE_DIALOG__SAVE: "Save",
    SAVE_ATTRIBUTE_DIALOG__SUCCESS: "Attribute Config added successfully.",
    SAVE_DB_DIALOG__ADD_DATABASE: "Add database",
    SAVE_DB_DIALOG__CANCEL: "Cancel",
    SAVE_DB_DIALOG__CREDENTIALS: "Credentials",
    SAVE_DB_DIALOG__DATABASE_CODE: "Database code",
    SAVE_DB_DIALOG__DATABASE_NAME: "Database name",
    SAVE_DB_DIALOG__DIALECT: "Dialect",
    SAVE_DB_DIALOG__ENTER_VALID_JSON_DATA_PLATFORM: "Please enter a valid JSON value in Extra for Data Platform.",
    SAVE_DB_DIALOG__ENTER_VALID_JSON_INTERNAL: "Please enter a valid JSON value in Extra for Internal.",
    SAVE_DB_DIALOG__ERROR_MESSAGE:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    SAVE_DB_DIALOG__ERROR: "An error has occurred.",
    SAVE_DB_DIALOG__EXTRA: "Extra",
    SAVE_DB_DIALOG__HOST: "Host",
    SAVE_DB_DIALOG__PASSWORD: "Password",
    SAVE_DB_DIALOG__PORT: "Port",
    SAVE_DB_DIALOG__PRIVILEGE: "Privilege",
    SAVE_DB_DIALOG__SAVE: "Save",
    SAVE_DB_DIALOG__SERVICE: "Service",
    SAVE_DB_DIALOG__SUCCESS: "Database added successfully",
    SAVE_DB_DIALOG__USERNAME: "Username",
    SAVE_DB_DIALOG__VALUE: "Value (in JSON format)",
    SAVE_DB_DIALOG__VOCAB_SCHEMAS: "Vocab Schemas",
    SCHEMA_FILTER__LOADER: "Loading Schema Definition...",
    SCHEMA_FILTER__TEXT: "Select table filter",
    SEARCH_BAR__SEARCH_TERMS: "search terms",
    SEARCH_BAR__SEARCH: "Search",
    SEARCH_BAR_DATASET__PLACEHOLDER: "Search datasets",
    SELECT_DATASET__SELECT_DATASET: "Dataset",
    SELECT_RELEASE__SELECT_RELEASE: "Release",
    SELECT_RELEASE__SELECT_CURRENT: "Current",
    SETUP_MENU_ITEM__CONFIGURE: "Configure",
    PLUGIN_MENU_ITEM__INSTALL: "Initialize",
    PLUGIN_MENU_ITEM__DESCRIPTION: "Trigger default plugins installation",
    PLUGIN_MENU_ITEM__TITLE: "Plugins",
    PLUGIN_MENU_ITEM__STATUS: "Status",
    TRIGGER_PLUGIN_UPLOAD_DIALOG__ERROR: "An error has occurred.",
    TRIGGER_PLUGIN_UPLOAD_DIALOG__DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    TRIGGER_PLUGIN_UPLOAD_DIALOG__TITLE: "Install default plugins",
    TRIGGER_PLUGIN_UPLOAD_DIALOG__WARNING: "Installation may take a long time",
    TRIGGER_PLUGIN_UPLOAD_DIALOG__CANCEL: "Cancel",
    TRIGGER_PLUGIN_UPLOAD_DIALOG__CONFIRM: "Yes, install",
    SETUP_OVERVIEW__BACK: "Back to Setup",
    SETUP_OVERVIEW__SETUP: "Setup",
    SHARED_DRILLDOWN__BAR_CHART_TITLE: "Frequency Distribution",
    SHARED_DRILLDOWN__BAR_CHART_TOOLTIP_FORMAT: "Count: {b}<br />% of total number of persons: {c}",
    SHARED_DRILLDOWN__BAR_CHART_X_AXIS_NAME: 'Count ("x" or more Procedures)',
    SHARED_DRILLDOWN__BAR_CHART_Y_AXIS_NAME: "% of total number of persons",
    SHARED_DRILLDOWN__BOX_PLOT_CHART_1_TITLE: "Age at First Occurrence",
    SHARED_DRILLDOWN__BOX_PLOT_CHART_1_X_AXIS_NAME: "Gender",
    SHARED_DRILLDOWN__BOX_PLOT_CHART_1_Y_AXIS_NAME: "Age at First Occurrence",
    SHARED_DRILLDOWN__BOX_PLOT_CHART_2_TITLE: "Length of Era Distribution",
    SHARED_DRILLDOWN__BOX_PLOT_CHART_2_X_AXIS_NAME: "Length of Era",
    SHARED_DRILLDOWN__BOX_PLOT_CHART_2_Y_AXIS_NAME: "Days",
    SHARED_DRILLDOWN__DRILLDOWN_LOADER: "Loading {0} Drilldown for concept: {1}",
    SHARED_DRILLDOWN__ERROR_MESSAGE: "Error occurred when fetching data characterization {0} data",
    SHARED_DRILLDOWN__LOADER: "Loading {0} Reports",
    SHARED_DRILLDOWN__PIE_CHART_1_TITLE: "Value As Concept",
    SHARED_DRILLDOWN__PIE_CHART_2_TITLE: "Operator Concept",
    SHARED_DRILLDOWN__PIE_CHART_3_TITLE: "Qualifier Concept",
    SHARED_DRILLDOWN__PIE_CHART_4_TITLE: "Measurement Records by Unit",
    SHARED_DRILLDOWN__TREE_MAP_CHART_TITLE: " Tree Map",
    SIDE_DROPDOWN__OPEN_IN_NEW_TAB: "Open in new tab",
    SOURCE_TO_CONCEPT_MAP_TABLE__SOURCE_CODE: "Source Code",
    SOURCE_TO_CONCEPT_MAP_TABLE__SOURCE_CONCEPT_ID: "Source Concept ID",
    SOURCE_TO_CONCEPT_MAP_TABLE__SOURCE_VOCABULARY_ID: "Source Vocabulary ID",
    SOURCE_TO_CONCEPT_MAP_TABLE__SOURCE_CODE_DESCRIPTION: "Source Code Description",
    SOURCE_TO_CONCEPT_MAP_TABLE__TARGET_CONCEPT_ID: "Target Concept ID",
    SOURCE_TO_CONCEPT_MAP_TABLE__TARGET_VOCABULARY_ID: "Target Vocabulary ID",
    SOURCE_TO_CONCEPT_MAP_TABLE__VALID_START_DATE: "Start Date",
    SOURCE_TO_CONCEPT_MAP_TABLE__VALID_END_DATE: "End Date",
    SOURCE_TO_CONCEPT_MAP_TABLE__INVALID_REASON: "Invalid Reason",
    STARBOARD__ERROR_CREATE: "An error has occurred while creating a new notebook",
    STARBOARD__ERROR_FETCH: "An error has occurred while fetching notebooks",
    STARBOARD__ERROR_IMPORT: "An error has occurred. Please import Jupyter files(.ipynb) only.",
    STUDY_CARD__DATASET_INFORMATION: "Dataset information",
    STUDY_CARD__NO_DATASET_SUMMARY: "No dataset summary available",
    STUDY_CARD__STUDY_CODE: "Study code",
    STUDY_CARD__UNTITLED: "Untitled",
    STUDY_MENU__DATASET_INFORMATION: "Dataset information",
    STUDY_MENU__MENU: "Menu",
    STUDY_NAV__NO_STUDY: "No study",
    STUDY_NAV__UNTITLED: "Untitled",
    STUDY_OVERVIEW__ACTIONS: "Actions",
    STUDY_OVERVIEW__ADD_DATASET: "Add dataset",
    STUDY_OVERVIEW__UPDATE_DATASET_METADATA: "Update dataset metadata",
    STUDY_OVERVIEW__DATA_MODEL: "Data model",
    STUDY_OVERVIEW__DATASETS: "Datasets",
    STUDY_OVERVIEW__LATEST_AVAILABLE: "Latest available schema version",
    STUDY_OVERVIEW__NAME: "Name",
    STUDY_OVERVIEW__NO_DATA: "No data available",
    STUDY_OVERVIEW__SCHEMA_NAME: "Schema name",
    STUDY_OVERVIEW__SCHEMA_VERSION: "Schema version",
    STUDY_OVERVIEW__DATASET_ID: "Dataset ID",
    STUDY_OVERVIEW__UNTITLED: "Untitled",
    SUPER_ADMIN_PAGE__GET_JWT_TOKEN: "Get JWT token",
    SUPER_ADMIN_PAGE__HELLO: "Hello {0}",
    SUPER_ADMIN_PAGE__SIDE_NAV: "Side navigation",
    SUPER_ADMIN_PAGE__SUBTITLE: "Sub header",
    SUPER_ADMIN_PAGE__TITLE: "[Built-in] Super admin plugin page",
    SYSTEM_ADMIN_PAGE__GET_JWT_TOKEN: "Get JWT token",
    SYSTEM_ADMIN_PAGE__HELLO: "Hello {0}",
    SYSTEM_ADMIN_PAGE__SIDE_NAV: "Side navigation",
    SYSTEM_ADMIN_PAGE__SUBTITLE: "Sub header",
    SYSTEM_ADMIN_PAGE__TITLE: "[Built-in] System admin plugin page",
    TAG_TABLE__ADD_TAG: "Add Tag",
    TAG_TABLE__DELETE: "Delete",
    TAG_TABLE__LOADER: "Loading Tag Configs",
    TAG_TABLE__TAG: "Tag",
    TERMINOLOGY__CLOSE: "Close",
    TERMINOLOGY__CONCEPT_SET_NAME: "Concept set name",
    TERMINOLOGY__CONCEPT_SETS: "Concept Sets",
    TERMINOLOGY__CONCEPTS: "Concepts",
    TERMINOLOGY__CREATE: "Create",
    TERMINOLOGY__CREATING: "creating",
    TERMINOLOGY__ERROR: "Error {0} concept set.",
    TERMINOLOGY__MISSING_USER_ID: "Missing User Id",
    TERMINOLOGY__NAME: "Name",
    TERMINOLOGY__REFERENCE_CONCEPTS: "Reference concepts from dataset",
    TERMINOLOGY__RELATED_CONCEPTS: "Related concepts",
    TERMINOLOGY__SEARCH: "Search",
    TERMINOLOGY__SELECTED_CONCEPTS: "Selected concepts",
    TERMINOLOGY__SHARED: "Shared",
    TERMINOLOGY__UPDATE: "Update",
    TERMINOLOGY__UPDATING: "updating",
    TERMINOLOGY_DETAIL__CONCEPT_CLASS_ID: "Concept Class ID",
    TERMINOLOGY_DETAIL__CONCEPT_CODE: "Concept code",
    TERMINOLOGY_DETAIL__CONCEPT_ID: "Concept ID",
    TERMINOLOGY_DETAIL__DETAILS: "Details",
    TERMINOLOGY_DETAIL__DOMAIN_ID: "Domain ID",
    TERMINOLOGY_DETAIL__ERROR_DESCRIPTION: "Please try again.",
    TERMINOLOGY_DETAIL__ERROR: "An error has occurred",
    TERMINOLOGY_DETAIL__HIERARCHY: "Hierarchy",
    TERMINOLOGY_DETAIL__RELATED_CONCEPTS: "Related Concepts",
    TERMINOLOGY_DETAIL__RELATES_TO: "Relates to",
    TERMINOLOGY_DETAIL__RELATIONSHIP: "Relationship",
    TERMINOLOGY_DETAIL__VALIDITY: "Validity",
    TERMINOLOGY_DETAIL__VOCABULARY_ID: "Vocabulary ID",
    TERMINOLOGY_DETAIL__VOCABULARY: "Vocabulary",
    TERMINOLOGY_LIST__: "Please try again.",
    TERMINOLOGY_LIST__CLASS: "Class",
    TERMINOLOGY_LIST__CODE: "Code",
    TERMINOLOGY_LIST__CONCEPT: "Concept",
    TERMINOLOGY_LIST__DESCENDANTS: "Descendants",
    TERMINOLOGY_LIST__DOMAIN: "Domain",
    TERMINOLOGY_LIST__ERROR_DESCRIPTION: "Please try again.",
    TERMINOLOGY_LIST__ERROR: "An error has occurred",
    TERMINOLOGY_LIST__ID: "ID",
    TERMINOLOGY_LIST__MAPPED: "Mapped",
    TERMINOLOGY_LIST__NAME: "Name",
    TERMINOLOGY_LIST__VALIDITY: "Validity",
    TERMINOLOGY_LIST__VOCABULARY: "Vocabulary",
    TEST_KEY: "default",
    TREE_MAP_CHART__CHART_LEGEND: "Box Size: Prevalence, Color: Records per person (Green to Grey = High to Low)",
    TREE_MAP_CHART__NUMBER_OF_PEOPLE: "Number of people",
    TREE_MAP_CHART__PREVALENCE: "Prevalence",
    TREE_MAP_CHART__RECORDS_PER_PERSON: "Records per person",
    TREE_MAP_CHART_TABLE__LABEL_TABLE: "Table",
    TREE_MAP_CHART_TABLE__LABEL_TREEMAP: "Treemap",
    TREE_MAP_CHART_TABLE__NO_DATA: "No data",
    TREE_MAP_TABLE__HEADER_CONCEPT_ID: "Concept Id",
    TREE_MAP_TABLE__HEADER_CONCEPT_PATH: "Name",
    TREE_MAP_TABLE__HEADER_NUM_PERSONS: "Person Count",
    TREE_MAP_TABLE__HEADER_PERCENT_PERSONS: "Prevalence",
    TREE_MAP_TABLE__HEADER_RECORDS_PER_PERSON: "Length of era",
    TREX_PLUGINS__INSTALL_FAILED_ERROR_MESSAGE: "Installation failed",
    TREX_PLUGINS__INSTALL_FAILED_ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    TREX_PLUGINS__INSTALL_NEW_PLUGIN: "Install new plugin",
    TREX_PLUGINS__INSTALL_SUCCESS: "Installation successful",
    TREX_PLUGINS__INSTALLING: "Installing...",
    TREX_PLUGINS__LATEST_VERSION: "Latest version",
    TREX_PLUGINS__NAME: "Name",
    TREX_PLUGINS__NO_DATA: "No plugins",
    TREX_PLUGINS__REGISTRY_VERSION: "Registry version",
    TREX_PLUGINS__STATUS: "Status",
    TREX_PLUGINS__TITLE: "Plugins",
    TREX_PLUGINS__UNINSTALL: "Uninstall",
    TREX_PLUGINS__UPDATE_FAILED_ERROR_MESSAGE: "Update failed",
    TREX_PLUGINS__UPDATE_FAILED_ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    TREX_PLUGINS__UPDATE_SUCCESS: "Update successful",
    TREX_PLUGINS__URL: "URL",
    TREX_PLUGINS__VERSION: "Version",
    TREX_PLUGIN_INSTALL_DIALOG__CANCEL: "Cancel",
    TREX_PLUGIN_INSTALL_DIALOG__ERROR: "Error while installing plugin",
    TREX_PLUGIN_INSTALL_DIALOG__ERROR_DESCRIPTION:
      "Please try again. To report the error, please send an email to help@data4life.care.",
    TREX_PLUGIN_INSTALL_DIALOG__INSTALL: "Install",
    TREX_PLUGIN_INSTALL_DIALOG__NAME: "Name",
    TREX_PLUGIN_INSTALL_DIALOG__TITLE: "Install new plugin",
    TREX_PLUGIN_UNINSTALL_DIALOG__CANCEL: "Cancel",
    TREX_PLUGIN_UNINSTALL_DIALOG__CONFIRM_1: "Are you sure you want to uninstall the following",
    TREX_PLUGIN_UNINSTALL_DIALOG__CONFIRM_2: "plugin",
    TREX_PLUGIN_UNINSTALL_DIALOG__CONFIRM_3: "Type the plugin name to confirm.",
    TREX_PLUGIN_UNINSTALL_DIALOG__CONFIRM_UNINSTALL: "Confirm uninstall",
    TREX_PLUGIN_UNINSTALL_DIALOG__ENTER_EXACT_FLOW_NAME: "Enter the exact plugin name",
    TREX_PLUGIN_UNINSTALL_DIALOG__ENTER_FLOW_NAME: "Enter plugin name",
    TREX_PLUGIN_UNINSTALL_DIALOG__ERROR: "Plugin {0} failed to uninstall",
    TREX_PLUGIN_UNINSTALL_DIALOG__UNINSTALL: "Uninstall plugin",
    UPDATE_SCHEMA_DIALOG__CANCEL: "Cancel",
    UPDATE_SCHEMA_DIALOG__CONFIRM: "Are you sure you want to update the schema of this dataset:",
    UPDATE_SCHEMA_DIALOG__ERROR: "Schema for dataset {0} failed to update",
    UPDATE_SCHEMA_DIALOG__UPDATE_SCHEMA: "Update schema",
    UPDATE_SCHEMA_DIALOG__UPDATE: "Yes, update",
    UPDATE_STUDY_DIALOG__ADD_METADATA: "add metadata",
    UPDATE_STUDY_DIALOG__CANCEL: "Cancel",
    UPDATE_STUDY_DIALOG__CODE_REQUIREMENT: "Should only contain letters, numbers, and underscores",
    UPDATE_STUDY_DIALOG__DATASET_INFO_CONFIG: "Dataset info configuration",
    UPDATE_STUDY_DIALOG__DATASET_NAME: "Dataset name - Displayed on buttons and headers",
    UPDATE_STUDY_DIALOG__DATASET_SUMMARY: "Dataset summary",
    UPDATE_STUDY_DIALOG__DATASET_VISIBILITY: "Dataset Visibility",
    UPDATE_STUDY_DIALOG__DESCRIPTION: "Description",
    UPDATE_STUDY_DIALOG__HIDDEN: "Hidden (only researchers and the tenant admin)",
    UPDATE_STUDY_DIALOG__METADATA: "Metadata",
    UPDATE_STUDY_DIALOG__PA_CONFIG: "PA Config",
    UPDATE_STUDY_DIALOG__PRIVATE: "Private (only users from the tenant)",
    UPDATE_STUDY_DIALOG__PUBLIC: "Public",
    UPDATE_STUDY_DIALOG__REQUEST: "Show request access button",
    UPDATE_STUDY_DIALOG__REQUIRED: "This is required",
    UPDATE_STUDY_DIALOG__SAVE: "Save",
    UPDATE_STUDY_DIALOG__TAGS: "Tags",
    UPDATE_STUDY_DIALOG__TOKEN_CODE: "Token dataset code",
    UPDATE_STUDY_DIALOG__TYPE: "Type",
    UPDATE_STUDY_DIALOG__UPDATE_DATASET: "Update dataset",
    UPDATE_STUDY_DIALOG__VALID_TOKEN_CODE: "Please enter a valid Token dataset code",
    USER_OVERVIEW__ADD_USER: "Add user",
    USER_OVERVIEW__DELETE: "Delete",
    USER_OVERVIEW__EDIT: "Edit",
    USER_OVERVIEW__NO_DATA: "No data available",
    USER_OVERVIEW__ROLE: "Role",
    USER_OVERVIEW__TENANT: "Tenant",
    USER_OVERVIEW__USERNAME: "Username",
    USER_OVERVIEW__USERS: "Users",
  },
};

function getKeyMap<T extends object>(obj: T) {
  const result = {} as Record<keyof T, keyof T>;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key as keyof T] = key as keyof T;
    }
  }
  return result as { [K in keyof T]: K };
}

// Exposing the default key map so that getText('MRI_PA_FILTERCARD_SELECTION_NONE')
// can be getText(i18nKeys.MRI_PA_FILTERCARD_SELECTION_NONE)
// to prevent typos with the values
export const i18nKeys = getKeyMap(i18nDefault.default);

export interface TranslationState {
  locale: string;
  translations: { [key: string]: typeof i18nDefault.default };
}
